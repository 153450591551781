/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useContext } from 'react';

/// /// EXTERNAL /// ///
// third party libraries //
import axios from 'axios';
/// /// INTERNAL /// ///
// context //
import { ErrorContext } from 'context/ErrorContext';

const Interceptors = () => {
  const {
    setMessage,
  } = useContext(ErrorContext);

  axios.interceptors.request.use(
    (request) => request,
    (error) => {
      if (error?.response?.data?.message) {
        setMessage({
          open: true,
          severity: 'error',
          text: error?.response?.data?.message,
        });
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;
        const { status } = response;
        if (status !== 301 && status !== 403 && status !== 401) {
          setMessage({
            open: true,
            severity: 'error',
            text: response?.data?.message,
          });
        }
      } else {
        const { message } = error;
        // canceled by AbortController
        if (!message || message !== 'canceled') {
          setMessage({
            open: true,
            severity: 'error',
            text: 'Lo sentimos, pero no podemos procesar su solicitud en este momento.',
          });
        }
      }
      return Promise.reject(error);
    },
  );
  return null;
};

export default Interceptors;
