/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// mui //
import {
  Box, Fade, Modal, Backdrop, Typography,
} from '@mui/material';
// hook //
import { useResponsive } from '@prosperia/hooks.use-responsive';

const CommonModal = ({
  open,
  icon,
  title,
  buttons,
  children,
  customResponsive,
  backdrop = true,
}) => {
  const { downSm, theme } = useResponsive();

  const primary = theme.palette.primary.main;

  const customColor = theme.custom.color;

  let titleVariant = 'h5';
  let containerWidth = '568px';
  if (downSm) {
    titleVariant = 'h6';
    containerWidth = '328px';
  }

  let responsive = {
    typography: {
      title: {
        variant: titleVariant,
        style: {
          fontWeight: 800,
          color: primary,
          marginBottom: '8px',
        },
      },
    },
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: containerWidth,
      backgroundColor: customColor,
      borderRadius: '32px',
      padding: '32px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
      boxSizing: 'border-box',
      borderColor: 'transparent',
      outline: 'none',
    },
    containerTitle: {
      marginBottom: '16px',
    },
    containerButton: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '10px',
      gap: '32px',
    },
  };

  if (customResponsive) responsive = customResponsive(responsive);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={backdrop ? { backdrop: Backdrop } : undefined}
      slotProps={backdrop ? {
        backdrop: {
          timeout: 500,
        },
      } : undefined}
    >
      <Fade in={open} timeout={500}>
        <Box sx={responsive.container}>
          <Box sx={responsive.containerTitle}>
            {icon}
            <Typography
              variant={responsive.typography.title.variant}
              sx={responsive.typography.title.style}
            >
              {title}
            </Typography>

            {children}
          </Box>
          <Box sx={responsive.containerButton}>
            {buttons}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CommonModal;
