/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useState } from 'react';
/// /// INTERNAL /// ///
// components //
import RecaptchaModal from 'components/atoms/recaptchaModal/RecaptchaModal';

// components //
import Report from './Report';

const RecaptchaReport = ({ skipRecaptha }) => {
  const [openModal, setOpenModal] = useState(true);

  if (!skipRecaptha) {
    if (openModal) {
      return (
        <RecaptchaModal
          setOpenModal={setOpenModal}
        />
      );
    }
  }

  return <Report />;
};

export default RecaptchaReport;
