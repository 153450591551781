/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import {
  createContext, useState, useMemo, useEffect,
} from 'react';
// third party libraries //
import { useParams } from 'react-router-dom';
// services //
import challengeApi from 'services/challengeService';
// service //
import { reportApi } from 'services/reportService';

export const InteractionContext = createContext();

export const InteractionProvider = ({ children }) => {
  const { interaction_id: interactionId, channelID, POC } = useParams();
  const [interaction, setInteraction] = useState({});
  const [secret, setSecret] = useState(null);
  const [loadedReport, setLoadedReport] = useState(false);
  const [skipRecaptha, setSkipRecaptha] = useState(false);
  const [pdfData, setPdfData] = useState({
    logoBase64: '',
    imagesBase64: null,
  });

  useEffect(() => {
    let isSubscribed = true;
    const controller = new AbortController();
    const getChallenge = async () => {
      const payload = {
        interaction_id: interactionId,
      };
      await challengeApi(payload, controller)
        .then((info) => {
          if (isSubscribed) {
            const result = info.data;
            const { challenge, skip } = result.data;
            if (skip) {
              reportApi(interactionId, channelID, POC, '').then((reportInfo) => {
                const reportResult = reportInfo.data;
                let data;
                if (reportResult.output) data = reportResult.output;
                else data = reportResult.data;
                setInteraction(data);
                setLoadedReport(true);
                setSkipRecaptha(skip);
              }).catch((errorReport) => {
                console.log('Unexpected Error get Reports', errorReport);
              });
            } else {
              setLoadedReport(true);
              setSecret(challenge);
            }
          }
        })
        .catch((errorChallenge) => {
          console.log(errorChallenge);
        });
    };
    getChallenge();

    return () => {
      isSubscribed = false;
      controller.abort();
    };
  }, [interactionId, POC, channelID]);

  const {
    poc_info: pocInfo,
    pacient_info: patient,
    interaction_parameters: interactionParameters,
    report,
  } = interaction;

  const context = useMemo(
    () => ({
      pdfData,
      pocInfo,
      patient,
      interactionParameters,
      report,
      secret,
      loadedReport,
      skipRecaptha,
      setInteraction,
      setPdfData,
    }),
    [
      pdfData,
      pocInfo,
      patient,
      interactionParameters,
      report,
      secret,
      loadedReport,
      skipRecaptha,
      setInteraction,
      setPdfData],
  );

  return (
    <InteractionContext.Provider
      value={context}
    >
      {children}
    </InteractionContext.Provider>
  );
};
