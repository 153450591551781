/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// third party libraries //
import axios from 'axios';

const challengeApi = async (payload, controller) => {
  const url = process.env.REACT_APP_API_POST_CHALLENGE;
  return axios.post(url, {
    data: payload,
  }, {
    signal: controller.signal,
    crossdomain: true,
  });
};

export default challengeApi;
