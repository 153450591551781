/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
export const SectionType = {
  TABLE: 'table',
  LIST: 'list',
  BULLET: 'bullet',
};

export const RenderColumns = {
  LANDSCAPE: {
    'retinia-adult': {
      4: {
        0: 5.7,
        1: 2.3,
        2: 2,
        3: 2,
      },
      3: {
        0: 5.7,
        1: 2.3,
        2: 4,
      },
      2: {
        0: 8,
        1: 4,
      },
      1: {
        0: 12,
      },
    },
    'retinia-rop': {
      3: {
        0: 6,
        1: 4,
        2: 2,
      },
      2: {
        0: 10,
        1: 2,
      },
      1: {
        0: 12,
      },
    },
    comment: {
      2: {
        0: 4,
        1: 8,
      },
      1: {
        0: 12,
      },
    },

  },
  PORTRAIT: {
    'retinia-adult': {
      3: {
        0: 7,
        1: 2,
        2: 3,
      },
      2: {
        0: 8,
        1: 4,
      },
      1: {
        0: 12,
      },
    },
    'retinia-rop': {
      3: {
        0: 7,
        1: 2,
        2: 3,
      },
      2: {
        0: 8,
        1: 4,
      },
      1: {
        0: 12,
      },
    },
    comment: {
      2: {
        0: 8,
        1: 4,
      },
      1: {
        0: 12,
      },
    },
  },
};

export const EXCLUDE_COUNTRIES_PHONE = [
  'asm',
  'ago',
  'ben',
  'io',
  'bq',
  'cg',
  'ci',
  'cu',
  'cw',
  'fk',
  'ir',
  'ki',
  'nr',
  'nf',
  'mp',
  'prk',
  'pw',
  'bl',
  'sh',
  'mf',
  'pm',
  'vc',
  'sx',
  'sd',
  'sy',
  'tk',
  'vir',
  'vat',
  'wf',
];

export const INITIALIZE_PATIENT = {
  name: '',
  lastName: '',
  birthDate: null,
  patientId: '',
  enabledTerms: false,
  enabledPrivacy: false,
  operatorField: '',
  horusIP: '',
  phone: '',
  email: '',
  phoneCode: '+52',
  countryCode: 'MX',
  age: '',
  pairingId: '',
};
